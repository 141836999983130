import axios from "axios"

 
const instance = axios.create({
	baseURL: "http://106.75.13.203:4035/", 
	credentials:true,
	timeout: 2000
})

instance.interceptors.request.use(config => {
	config.header = { 
		  'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8' 
	}
	
	return config
},err =>{
	return Promise.reject(err)
})

export default instance
